// var alternate_img = document.getElementById('image-alternate');
//
// if(alternate_img){
//   alternate_img.onclick = function(){
//    this.src = this.getAttribute('data-animsrc');
//    this.srcset = this.getAttribute('data-animsrc');
//    console.log(this.getAttribute('data-animsrc'));
//   }
//
// }
