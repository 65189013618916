import Flickity from 'flickity-fade';
import 'flickity-imagesloaded';

let detail = document.querySelector('.detail');

// init
if (detail) {
  var
    header = document.querySelector('#header'),
    footer = document.querySelector('#footer'),
    el = detail.querySelector('.flickity'),
    list = el.querySelector('.flickity__list'),
    itemsImg = el.querySelectorAll('.flickity__item img'),
    btnPrev = detail.querySelector('.flickity__btn--prev'),
    btnNext = detail.querySelector('.flickity__btn--next'),
    counterSelected = detail.querySelector('.flickity__counter-selected'),
    counterLength = detail.querySelector('.flickity__counter-length');

  let options = {
    cellAlign: 'left',
    imagesLoaded: true,
    prevNextButtons: false,
    dragThreshold: 5,
    wrapAround: true,
    pageDots: false,
    fade: false,
    draggable: true,
    fullscreen: true,
    on: {
      ready() {
        setTimeout(() => {
          counterLength.innerHTML = flkty.cells.length;
          counterSelected.innerHTML = flkty.selectedIndex + 1;
        }, 1);
      },
      change() {
        counterSelected.innerHTML = flkty.selectedIndex + 1;
      }
    }
  }

  let flkty = new Flickity(list, options);

  btnPrev.addEventListener('click', () => flkty.previous());
  btnNext.addEventListener('click', () => flkty.next());

  flkty.on( 'staticClick', function() {
    flkty.next()
  })

  // ZOOM

  // flkty.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
  //   console.log(cellElement.classList);
  //   // dismiss if cell was not clicked
  //   if ( !cellElement ) {
  //     return;
  //   }
  //
  //   if (!el.classList.contains('flickity--fullscreen')) {
  //     el.classList.add('flickity--fullscreen')
  //     document.body.style.overflow = 'hidden';
  //     header.style.zIndex = '0';
  //     footer.style.display = 'none';
  //
  //   } else {
  //     el.classList.remove('flickity--fullscreen')
  //     document.body.style.overflow = '';
  //     header.style.zIndex = '1';
  //     footer.style.display = '';
  //   }
  //
  // });

  // document.querySelector('.flickity__fullscreen-exit').addEventListener('click', function () {
  //   el.classList.remove('flickity--fullscreen')
  //   document.body.style.overflow = '';
  //   header.style.zIndex = '1';
  //   footer.style.display = '';
  // })
  //
  // window.addEventListener('keydown', function (e) {
  //   if (e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27) {
  //     el.classList.remove('flickity--fullscreen')
  //     document.body.style.overflow = '';
  //     header.style.zIndex = '1';
  //     footer.style.display = '';
  //   }
  // });
}



// FIX: page vertical scrolling (firefox mobile)
let
  tapArea = document.querySelectorAll('.flickity-enabled'),
  startX = 0;
for (let item of tapArea) {
  item.ontouchstart = e => startX = e.touches[0].clientX;
  item.ontouchmove = e => {
    if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
      e.preventDefault();
    }
  };
}
