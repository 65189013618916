// function spanify(el) {

// }

// spanify(document.querySelectorAll('spanify'));

function wavify(el, curve, random = false) {

  if (!el) return;
  // Check if selector is a list or not
  if (el.length >= 1) {
    let list = el;
    for (let el of list) {
      main(el);
    }
  } else {
    main(el);
  }

  // Main function
  function main(el) {
    let letters = el.textContent.split('');
    el.textContent = '';
    el.style.display = 'flex';
    el.style.flexWrap = 'wrap';
    for (let i = 0; i < letters.length; i++) {
      let span = document.createElement('span');
      span.textContent = letters[i];
      span.style.position = 'relative';
      if (random) {
        span.style.top = Math.floor(Math.random() * 2) * curve + 'rem';
      } else {
        span.style.top = i / curve + 'rem';
      }
      // el.classList.add('play')
      el.appendChild(span);
    }

    document.addEventListener('click', function (ev) {
      let link = ev.target.parentNode;
      if (link.classList.contains('play')) {
        // Cancel a href
        ev.preventDefault();
        ev.stopPropagation();
        // Move letter
        let j = 0;
        for (let el of link.children) {
          el.style.top = j / (Math.floor(Math.random() * -2) * curve ) + 'rem';
          j++;
          // el.style.top = Math.floor(Math.random() * 2) * curve + 'rem';
        }
        // Go to link after .5 sec
        if(link.href){
          setTimeout(function() {
            window.location.href = link.href;
          }, 500);
          return false;
        }
      }
    })
  }
}

wavify(document.querySelector('.index__menu .nav__link--active'), 0.5, true);
wavify(document.querySelector('.header .nav__link--active'), 6);
wavify(document.querySelector('.logo__club'), 3);
wavify(document.querySelector('.detail__title'), 2, true);
